.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 120vh;
    

    display: flex;
    justify-content: center;
    align-items: center;
}

.popup-inner{
    position: absolute;
    padding: 100px;
    width: 40%;

    background-color: white;
}

.popup-inner .close-btn {
    position: absolute;
    top: 16px;
    right: 16px;
}